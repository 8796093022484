import * as React from 'react';
import { Svg } from '../svg';
import { BoxProps } from '../box';

export const BlockstackIcon: React.FC<BoxProps> = props => (
  <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.148042 1.08513C-5.58794e-08 1.42724 -3.80998e-08 1.86349 0 2.736L1.42713e-07 6L2.85387e-07 9.264C3.23525e-07 10.1365 0 10.5728 0.148042 10.9149C0.329866 11.335 0.664964 11.6701 1.08513 11.852C1.42724 12 1.86349 12 2.736 12H6H9.264C10.1365 12 10.5728 12 10.9149 11.852C11.335 11.6701 11.6701 11.335 11.852 10.9149C12 10.5728 12 10.1365 12 9.264V6V2.736C12 1.86349 12 1.42724 11.852 1.08513C11.6701 0.664963 11.335 0.329865 10.9149 0.148041C10.5728 -6.70552e-08 10.1365 -3.80998e-08 9.264 0L6 1.42713e-07L2.736 2.85387e-07C1.86349 3.23525e-07 1.40625 2.90573e-07 1.08513 0.148042C0.664964 0.329867 0.329866 0.664963 0.148042 1.08513ZM7.91566 5.16965C7.31682 5.16965 6.83125 4.68409 6.83125 4.08511C6.83125 3.48644 7.31682 3.00088 7.91566 3.00088C8.5145 3.00088 9.00007 3.48644 9.00007 4.08511C9.00007 4.68409 8.5145 5.16965 7.91566 5.16965ZM5.16787 4.085C5.16787 4.68358 4.68253 5.16893 4.08382 5.16893C3.48541 5.16893 3.00007 4.68358 3.00007 4.085C3.00007 3.48643 3.48541 3.00107 4.08382 3.00107C4.68253 3.00107 5.16787 3.48643 5.16787 4.085ZM7.91576 6.83459C7.31679 6.83459 6.83123 7.32016 6.83123 7.919C6.83123 8.51785 7.31679 9.00342 7.91576 9.00342C8.51444 9.00342 9 8.51785 9 7.919C9 7.32016 8.51444 6.83459 7.91576 6.83459ZM4.08392 6.83565C4.68248 6.83565 5.16783 7.32098 5.16783 7.91969C5.16783 8.51809 4.68248 9.00342 4.08392 9.00342C3.48535 9.00342 3 8.51809 3 7.91969C3 7.32098 3.48535 6.83565 4.08392 6.83565Z"
      fill={props.color || 'currentColor'}
    />
  </Svg>
);
