import React from 'react';
import Styled from 'styled-components';

const AppsIcon: React.FC = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect y="1.02686" width="10.0435" height="9.88235" rx="1.75" fill="currentColor" />
    <path
      opacity="0.8"
      d="M13.5697 10.9091H22.2999C23.0625 10.9091 23.5444 10.0897 23.1739 9.42317L18.8088 1.57201C18.4277 0.886573 17.4419 0.886572 17.0608 1.572L12.6957 9.42316C12.3251 10.0897 12.8071 10.9091 13.5697 10.9091Z"
      fill="currentColor"
    />
    <rect
      opacity="0.64"
      y="13.7327"
      width="10.4348"
      height="10.2674"
      rx="5.13369"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.672 14.0339C14.4783 13.8498 14.2188 13.7479 13.9494 13.7502C13.68 13.7525 13.4224 13.8588 13.2319 14.0462C13.0414 14.2337 12.9334 14.4872 12.931 14.7523C12.9287 15.0173 13.0322 15.2726 13.2194 15.4633L16.4894 18.6809L13.2194 21.8984C13.0266 22.088 12.9182 22.3451 12.9182 22.6133C12.9181 22.746 12.9446 22.8775 12.9962 23.0002C13.0478 23.1229 13.1235 23.2344 13.2189 23.3283C13.3142 23.4222 13.4275 23.4967 13.5521 23.5476C13.6768 23.5984 13.8104 23.6246 13.9453 23.6247C14.2179 23.6248 14.4793 23.5183 14.672 23.3288L17.9421 20.1112L21.2121 23.3288C21.4049 23.5185 21.6663 23.625 21.9389 23.625C22.2116 23.625 22.473 23.5185 22.6658 23.3288C22.8585 23.1391 22.9668 22.8819 22.9668 22.6136C22.9668 22.3454 22.8585 22.0881 22.6658 21.8984L19.3957 18.6809L22.6658 15.4633C22.7612 15.3694 22.8368 15.2579 22.8884 15.1352C22.94 15.0125 22.9665 14.881 22.9665 14.7483C22.9664 14.6155 22.9398 14.484 22.8881 14.3614C22.8364 14.2387 22.7607 14.1273 22.6653 14.0334C22.5698 13.9396 22.4565 13.8651 22.3318 13.8144C22.2071 13.7636 22.0735 13.7375 21.9386 13.7375C21.8036 13.7376 21.67 13.7638 21.5454 13.8146C21.4207 13.8655 21.3075 13.94 21.2121 14.0339L17.9421 17.2515L14.672 14.0339Z"
      fill="currentColor"
    />
  </svg>
);
const EncryptionIcon: React.FC = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18.5818 8.57143H17.7091V6C17.7091 4.4087 17.0655 2.88258 15.9198 1.75736C14.7741 0.632141 13.2202 0 11.6 0C9.97977 0 8.4259 0.632141 7.28022 1.75736C6.13454 2.88258 5.49091 4.4087 5.49091 6V8.57143H4.61818C3.92403 8.57218 3.25853 8.84334 2.76769 9.32541C2.27685 9.80748 2.00076 10.4611 2 11.1429V21.4286C2.00076 22.1103 2.27685 22.7639 2.76769 23.246C3.25853 23.7281 3.92403 23.9993 4.61818 24H18.5818C19.276 23.9993 19.9415 23.7281 20.4323 23.246C20.9231 22.7639 21.1992 22.1103 21.2 21.4286V11.1429C21.1992 10.4611 20.9231 9.80748 20.4323 9.32541C19.9415 8.84334 19.276 8.57218 18.5818 8.57143ZM7.23636 6C7.23636 4.86336 7.6961 3.77327 8.51444 2.96954C9.33278 2.16582 10.4427 1.71429 11.6 1.71429C12.7573 1.71429 13.8672 2.16582 14.6856 2.96954C15.5039 3.77327 15.9636 4.86336 15.9636 6V8.57143H7.23636V6Z"
      fill="currentColor"
    />
  </svg>
);
const BlockchainIcon: React.FC<{ color?: string }> = props => {
  const color = props.color || 'currentColor';

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <rect width="12" height="12" rx="2.25" fill={color} />
      <rect opacity="0.6" x="12" y="12" width="12" height="12" rx="2.25" fill={color} />
      <rect
        opacity="0.4"
        x="14.75"
        y="2.75"
        width="6.5"
        height="6.5"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        opacity="0.4"
        x="2.75"
        y="14.75"
        width="6.5"
        height="6.5"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PrivateIcon: React.FC = props => (
  <svg width="18" height="24" viewBox="0 0 18 24" fill="none" {...props}>
    <path
      d="M15.5455 8.57143H14.7273V6C14.7273 4.4087 14.1239 2.88258 13.0498 1.75736C11.9757 0.632141 10.519 0 9 0C7.48103 0 6.02428 0.632141 4.95021 1.75736C3.87614 2.88258 3.27273 4.4087 3.27273 6V8.57143H2.45455C1.80378 8.57218 1.17987 8.84334 0.719709 9.32541C0.259547 9.80748 0.000714665 10.4611 0 11.1429V21.4286C0.000714665 22.1103 0.259547 22.7639 0.719709 23.246C1.17987 23.7281 1.80378 23.9993 2.45455 24H15.5455C16.1962 23.9993 16.8201 23.7281 17.2803 23.246C17.7405 22.7639 17.9993 22.1103 18 21.4286V11.1429C17.9993 10.4611 17.7405 9.80748 17.2803 9.32541C16.8201 8.84334 16.1962 8.57218 15.5455 8.57143ZM4.90909 6C4.90909 4.86336 5.3401 3.77327 6.10729 2.96954C6.87449 2.16582 7.91502 1.71429 9 1.71429C10.085 1.71429 11.1255 2.16582 11.8927 2.96954C12.6599 3.77327 13.0909 4.86336 13.0909 6V8.57143H4.90909V6Z"
      fill="#5548FF"
    />
  </svg>
);

const MiniSVG = Styled.svg`
  margin-left: 4px;
  margin-right: 4px;
  display: inline;
  position: relative;
  top: -1px;
`;

const BlockstackMini: React.FC = props => (
  <MiniSVG width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.148042 1.08513C-5.58794e-08 1.42724 -3.80998e-08 1.86349 0 2.736L1.42713e-07 6L2.85387e-07 9.264C3.23525e-07 10.1365 0 10.5728 0.148042 10.9149C0.329866 11.335 0.664964 11.6701 1.08513 11.852C1.42724 12 1.86349 12 2.736 12H6H9.264C10.1365 12 10.5728 12 10.9149 11.852C11.335 11.6701 11.6701 11.335 11.852 10.9149C12 10.5728 12 10.1365 12 9.264V6V2.736C12 1.86349 12 1.42724 11.852 1.08513C11.6701 0.664963 11.335 0.329865 10.9149 0.148041C10.5728 -6.70552e-08 10.1365 -3.80998e-08 9.264 0L6 1.42713e-07L2.736 2.85387e-07C1.86349 3.23525e-07 1.40625 2.90573e-07 1.08513 0.148042C0.664964 0.329867 0.329866 0.664963 0.148042 1.08513ZM7.91566 5.16965C7.31682 5.16965 6.83125 4.68409 6.83125 4.08511C6.83125 3.48644 7.31682 3.00088 7.91566 3.00088C8.5145 3.00088 9.00007 3.48644 9.00007 4.08511C9.00007 4.68409 8.5145 5.16965 7.91566 5.16965ZM5.16787 4.085C5.16787 4.68358 4.68253 5.16893 4.08382 5.16893C3.48541 5.16893 3.00007 4.68358 3.00007 4.085C3.00007 3.48643 3.48541 3.00107 4.08382 3.00107C4.68253 3.00107 5.16787 3.48643 5.16787 4.085ZM7.91576 6.83459C7.31679 6.83459 6.83123 7.32016 6.83123 7.919C6.83123 8.51785 7.31679 9.00342 7.91576 9.00342C8.51444 9.00342 9 8.51785 9 7.919C9 7.32016 8.51444 6.83459 7.91576 6.83459ZM4.08392 6.83565C4.68248 6.83565 5.16783 7.32098 5.16783 7.91969C5.16783 8.51809 4.68248 9.00342 4.08392 9.00342C3.48535 9.00342 3 8.51809 3 7.91969C3 7.32098 3.48535 6.83565 4.08392 6.83565Z"
      fill="#A1A7B3"
    />
  </MiniSVG>
);

const EyeIcon: React.FC = props => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.695 0.351482C19.9032 0.135371 20.1868 0.00948757 20.4855 0.000511017C20.7171 -0.00642408 20.9454 0.057366 21.1405 0.183512C21.3356 0.309658 21.4883 0.492283 21.5786 0.707441C21.669 0.922599 21.6927 1.16026 21.6468 1.38926C21.6008 1.61827 21.4873 1.82793 21.3212 1.99078L4.3048 19.1469C4.19867 19.2575 4.07178 19.3456 3.93152 19.4062C3.79125 19.4668 3.64043 19.4987 3.48783 19.5C3.33523 19.5012 3.1839 19.4718 3.04268 19.4135C2.90146 19.3552 2.77316 19.2691 2.66526 19.1603C2.55735 19.0515 2.47201 18.9222 2.4142 18.7798C2.35639 18.6374 2.32727 18.4849 2.32854 18.331C2.3298 18.1771 2.36143 18.0251 2.42157 17.8837C2.48171 17.7423 2.56917 17.6143 2.67885 17.5074L19.695 0.351482ZM0.193655 8.95376C2.47935 4.5123 7.03523 1.71636 11.9982 1.71636C13.5143 1.71601 14.9919 1.97725 16.3789 2.46512L14.7583 4.09901C13.9263 3.68538 12.9904 3.45188 12 3.45188C8.55044 3.45188 5.754 6.27118 5.75403 9.74917C5.75404 10.7476 5.98562 11.6913 6.39588 12.5301L3.92169 15.0246C2.39514 13.8491 1.11258 12.3359 0.194802 10.5571C-0.0645738 10.0547 -0.0649106 9.45653 0.193655 8.95376ZM17.6041 6.96802L20.0781 4.47375C21.6047 5.64932 22.8874 7.16248 23.8052 8.94153C24.0646 9.44387 24.0649 10.042 23.8064 10.5448C21.5205 14.9863 16.9647 17.7818 12.0018 17.7818C10.4857 17.7821 9.00815 17.5209 7.62116 17.033L9.2415 15.3994C10.0735 15.813 11.0096 16.0465 12 16.0465C15.4496 16.0465 18.246 13.227 18.246 9.74917C18.246 8.75058 18.0145 7.80688 17.6041 6.96802ZM9.24284 6.96914C10.0042 6.20163 11.0022 5.81824 12 5.81824C12.3155 5.81824 12.6308 5.85684 12.9387 5.93349L8.2154 10.6956C7.89855 9.40286 8.24082 7.97945 9.24284 6.96914ZM11.0613 13.5647L15.7846 8.80249C16.1013 10.0952 15.7589 11.5184 14.7569 12.5285C13.755 13.5388 12.3434 13.8839 11.0613 13.5647Z"
      fill="#5548FF"
    />
    <path
      d="M19.6951 0.351482C19.9034 0.135371 20.1869 0.00948757 20.4856 0.000511017C20.7173 -0.00642408 20.9456 0.057366 21.1406 0.183512C21.3357 0.309658 21.4884 0.492283 21.5787 0.707441C21.6691 0.922599 21.6928 1.16026 21.6469 1.38926C21.6009 1.61827 21.4874 1.82793 21.3213 1.99078L4.30491 19.1469C4.19879 19.2575 4.07189 19.3456 3.93163 19.4062C3.79137 19.4668 3.64054 19.4987 3.48794 19.5C3.33534 19.5012 3.18402 19.4718 3.0428 19.4135C2.90157 19.3552 2.77327 19.2691 2.66537 19.1603C2.55747 19.0515 2.47213 18.9222 2.41432 18.7798C2.35651 18.6374 2.32739 18.4849 2.32865 18.331C2.32992 18.1771 2.36154 18.0251 2.42169 17.8837C2.48183 17.7423 2.56929 17.6143 2.67896 17.5074L19.6951 0.351482Z"
      fill="#5548FF"
    />
  </svg>
);

export { AppsIcon, BlockchainIcon, EncryptionIcon, PrivateIcon, BlockstackMini, EyeIcon };
