import * as React from 'react';
import { Svg } from '../svg';
import { BoxProps } from '../box';

export const EyeIcon: React.FC<BoxProps> = props => (
  <Svg width="24" height="20" viewBox="0 0 24 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.695 0.351482C19.9032 0.135371 20.1868 0.00948757 20.4855 0.000511017C20.7171 -0.00642408 20.9454 0.057366 21.1405 0.183512C21.3356 0.309658 21.4883 0.492283 21.5786 0.707441C21.669 0.922599 21.6927 1.16026 21.6468 1.38926C21.6008 1.61827 21.4873 1.82793 21.3212 1.99078L4.3048 19.1469C4.19867 19.2575 4.07178 19.3456 3.93152 19.4062C3.79125 19.4668 3.64043 19.4987 3.48783 19.5C3.33523 19.5012 3.1839 19.4718 3.04268 19.4135C2.90146 19.3552 2.77316 19.2691 2.66526 19.1603C2.55735 19.0515 2.47201 18.9222 2.4142 18.7798C2.35639 18.6374 2.32727 18.4849 2.32854 18.331C2.3298 18.1771 2.36143 18.0251 2.42157 17.8837C2.48171 17.7423 2.56917 17.6143 2.67885 17.5074L19.695 0.351482ZM0.193655 8.95376C2.47935 4.5123 7.03523 1.71636 11.9982 1.71636C13.5143 1.71601 14.9919 1.97725 16.3789 2.46512L14.7583 4.09901C13.9263 3.68538 12.9904 3.45188 12 3.45188C8.55044 3.45188 5.754 6.27118 5.75403 9.74917C5.75404 10.7476 5.98562 11.6913 6.39588 12.5301L3.92169 15.0246C2.39514 13.8491 1.11258 12.3359 0.194802 10.5571C-0.0645738 10.0547 -0.0649106 9.45653 0.193655 8.95376ZM17.6041 6.96802L20.0781 4.47375C21.6047 5.64932 22.8874 7.16248 23.8052 8.94153C24.0646 9.44387 24.0649 10.042 23.8064 10.5448C21.5205 14.9863 16.9647 17.7818 12.0018 17.7818C10.4857 17.7821 9.00815 17.5209 7.62116 17.033L9.2415 15.3994C10.0735 15.813 11.0096 16.0465 12 16.0465C15.4496 16.0465 18.246 13.227 18.246 9.74917C18.246 8.75058 18.0145 7.80688 17.6041 6.96802ZM9.24284 6.96914C10.0042 6.20163 11.0022 5.81824 12 5.81824C12.3155 5.81824 12.6308 5.85684 12.9387 5.93349L8.2154 10.6956C7.89855 9.40286 8.24082 7.97945 9.24284 6.96914ZM11.0613 13.5647L15.7846 8.80249C16.1013 10.0952 15.7589 11.5184 14.7569 12.5285C13.755 13.5388 12.3434 13.8839 11.0613 13.5647Z"
      fill={props.color || 'currentColor'}
    />
    <path
      d="M19.6951 0.351482C19.9034 0.135371 20.1869 0.00948757 20.4856 0.000511017C20.7173 -0.00642408 20.9456 0.057366 21.1406 0.183512C21.3357 0.309658 21.4884 0.492283 21.5787 0.707441C21.6691 0.922599 21.6928 1.16026 21.6469 1.38926C21.6009 1.61827 21.4874 1.82793 21.3213 1.99078L4.30491 19.1469C4.19879 19.2575 4.07189 19.3456 3.93163 19.4062C3.79137 19.4668 3.64054 19.4987 3.48794 19.5C3.33534 19.5012 3.18402 19.4718 3.0428 19.4135C2.90157 19.3552 2.77327 19.2691 2.66537 19.1603C2.55747 19.0515 2.47213 18.9222 2.41432 18.7798C2.35651 18.6374 2.32739 18.4849 2.32865 18.331C2.32992 18.1771 2.36154 18.0251 2.42169 17.8837C2.48183 17.7423 2.56929 17.6143 2.67896 17.5074L19.6951 0.351482Z"
      fill={props.color || 'currentColor'}
    />
  </Svg>
);
