import * as React from 'react';
import { Svg } from '../svg';
import { BoxProps } from '../box';

export const EncryptionIcon: React.FC<BoxProps> = props => (
  <Svg {...props}>
    <path
      d="M18.5818 8.57143H17.7091V6C17.7091 4.4087 17.0655 2.88258 15.9198 1.75736C14.7741 0.632141 13.2202 0 11.6 0C9.97977 0 8.4259 0.632141 7.28022 1.75736C6.13454 2.88258 5.49091 4.4087 5.49091 6V8.57143H4.61818C3.92403 8.57218 3.25853 8.84334 2.76769 9.32541C2.27685 9.80748 2.00076 10.4611 2 11.1429V21.4286C2.00076 22.1103 2.27685 22.7639 2.76769 23.246C3.25853 23.7281 3.92403 23.9993 4.61818 24H18.5818C19.276 23.9993 19.9415 23.7281 20.4323 23.246C20.9231 22.7639 21.1992 22.1103 21.2 21.4286V11.1429C21.1992 10.4611 20.9231 9.80748 20.4323 9.32541C19.9415 8.84334 19.276 8.57218 18.5818 8.57143ZM7.23636 6C7.23636 4.86336 7.6961 3.77327 8.51444 2.96954C9.33278 2.16582 10.4427 1.71429 11.6 1.71429C12.7573 1.71429 13.8672 2.16582 14.6856 2.96954C15.5039 3.77327 15.9636 4.86336 15.9636 6V8.57143H7.23636V6Z"
      fill="currentColor"
    />
  </Svg>
);
