import React from 'react';
import { Svg } from '../svg';
import { BoxProps } from '../box';

export const AppsIcon: React.FC<BoxProps> = props => (
  <Svg {...props}>
    <rect y="1.02686" width="10.0435" height="9.88235" rx="1.75" fill="currentColor" />
    <path
      opacity="0.8"
      d="M13.5697 10.9091H22.2999C23.0625 10.9091 23.5444 10.0897 23.1739 9.42317L18.8088 1.57201C18.4277 0.886573 17.4419 0.886572 17.0608 1.572L12.6957 9.42316C12.3251 10.0897 12.8071 10.9091 13.5697 10.9091Z"
      fill="currentColor"
    />
    <rect
      opacity="0.64"
      y="13.7327"
      width="10.4348"
      height="10.2674"
      rx="5.13369"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.672 14.0339C14.4783 13.8498 14.2188 13.7479 13.9494 13.7502C13.68 13.7525 13.4224 13.8588 13.2319 14.0462C13.0414 14.2337 12.9334 14.4872 12.931 14.7523C12.9287 15.0173 13.0322 15.2726 13.2194 15.4633L16.4894 18.6809L13.2194 21.8984C13.0266 22.088 12.9182 22.3451 12.9182 22.6133C12.9181 22.746 12.9446 22.8775 12.9962 23.0002C13.0478 23.1229 13.1235 23.2344 13.2189 23.3283C13.3142 23.4222 13.4275 23.4967 13.5521 23.5476C13.6768 23.5984 13.8104 23.6246 13.9453 23.6247C14.2179 23.6248 14.4793 23.5183 14.672 23.3288L17.9421 20.1112L21.2121 23.3288C21.4049 23.5185 21.6663 23.625 21.9389 23.625C22.2116 23.625 22.473 23.5185 22.6658 23.3288C22.8585 23.1391 22.9668 22.8819 22.9668 22.6136C22.9668 22.3454 22.8585 22.0881 22.6658 21.8984L19.3957 18.6809L22.6658 15.4633C22.7612 15.3694 22.8368 15.2579 22.8884 15.1352C22.94 15.0125 22.9665 14.881 22.9665 14.7483C22.9664 14.6155 22.9398 14.484 22.8881 14.3614C22.8364 14.2387 22.7607 14.1273 22.6653 14.0334C22.5698 13.9396 22.4565 13.8651 22.3318 13.8144C22.2071 13.7636 22.0735 13.7375 21.9386 13.7375C21.8036 13.7376 21.67 13.7638 21.5454 13.8146C21.4207 13.8655 21.3075 13.94 21.2121 14.0339L17.9421 17.2515L14.672 14.0339Z"
      fill="currentColor"
    />
  </Svg>
);
